import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import WheelFortune from 'wheel-fortune';
import PlanetaryParticles from './utils/PlanetaryParticles';

const App = {
  planetaryParticles: new PlanetaryParticles({
    particleColor: 0xff00ff,
    container: '.wheel__decor',
  }),

  registerGSAPPlugins() {
    gsap.registerPlugin(CustomEase);
    WheelFortune.registerGSAP(gsap, CustomEase);
  },

  setupWheel() {
    const spinStates = [
      { stopSegment: 4, callback: () => this.firstSpin() },
      { stopSegment: 1, callback: () => this.secondSpin() },
    ];

    this.wheel = new WheelFortune({
      spinStates,
      rotationCount: 4,
      segmentCount: 6,
      wheelLibration: true,
    });

    this.wheel.init();
  },

  firstSpin() {
    document.body.classList.add('first-spin-complete');
  },

  secondSpin() {
    document.body.classList.add('second-spin-complete');
  },

  async init() {
    this.registerGSAPPlugins();
    this.setupWheel();
    this.planetaryParticles.init();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
