import * as THREE from 'three';

class PlanetaryParticles {
  constructor({
    numParticles = 1250,
    particleSize = 0.1,
    orbitRadius = 15,
    orbitSpeed = 0.0015,
    lerpSpeed = 0.05,
    manualControlTimeout = 150,
    particleColor = 0xff00ff,
    container = 'body',
  } = {}) {
    // const getElement = (el) => (el instanceof HTMLElement ? el : document.querySelector(el));
    const getElement = (el) => {
      const result = el instanceof HTMLElement ? el : document.querySelector(el);
      console.log('Searching for:', el, 'Found:', result);
      return result;
    };
    this.container = getElement(container);

    if (!this.isContainerValid(this.container)) {
      return;
    }

    if (!this.isWebGLAvailable()) {
      return;
    }

    this.initFields(numParticles, particleSize, orbitRadius, orbitSpeed, lerpSpeed, manualControlTimeout, particleColor);
    this.initThreeJSComponents();
    this.initEventListeners();
  }

  initFields(numParticles, particleSize, orbitRadius, orbitSpeed, lerpSpeed, manualControlTimeout, particleColor) {
    this.numParticles = numParticles;
    this.particleSize = particleSize;
    this.orbitRadius = orbitRadius;
    this.orbitSpeed = orbitSpeed;
    this.lerpSpeed = lerpSpeed;
    this.manualControlTimeout = manualControlTimeout;
    this.particleColor = particleColor;
    this.manualControl = false;
    this.timeoutId = null;
    this.mouse = new THREE.Vector2();
    this.windowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);
  }

  initThreeJSComponents() {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.container.appendChild(this.renderer.domElement);

    this.particlesMaterial = new THREE.PointsMaterial({ color: this.particleColor, size: this.particleSize });
    this.particlesGeometry = new THREE.BufferGeometry();
    this.positions = new Float32Array(this.numParticles * 3);
    this.initPositions();
    this.planetaryParticles = new THREE.Points(this.particlesGeometry, this.particlesMaterial);
    this.scene.add(this.planetaryParticles);

    this.camera.position.z = 30;
    this.onResize();
  }

  initEventListeners() {
    window.addEventListener('resize', this.onResize);

    document.addEventListener('pointermove', this.onDocumentMouseMove);
  }

  isContainerValid(container) {
    return container !== null && container !== undefined;
  }

  isWebGLAvailable() {
    try {
      const canvas = document.createElement('canvas');

      return !!window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
    } catch (e) {
      return false;
    }
  }

  initPositions() {
    for (let i = 0; i < this.numParticles; i++) {
      const x = (Math.random() - 0.5) * 2;
      const y = (Math.random() - 0.5) * 2;
      const z = (Math.random() - 0.5) * 2;
      const normalized = new THREE.Vector3(x, y, z).normalize().multiplyScalar(this.orbitRadius);

      this.positions[i * 3] = normalized.x;
      this.positions[i * 3 + 1] = normalized.y;
      this.positions[i * 3 + 2] = normalized.z;
    }

    this.particlesGeometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3));
  }

  onDocumentMouseMove = (event) => {
    clearTimeout(this.timeoutId);

    this.manualControl = true;
    this.mouse.x = (event.clientX - this.windowHalf.x) / this.windowHalf.x;
    this.mouse.y = (event.clientY - this.windowHalf.y) / this.windowHalf.y;

    this.timeoutId = setTimeout(() => {
      this.manualControl = false;
    }, this.manualControlTimeout);
  };

  onResize = () => {
    const width = this.container.clientWidth;
    const height = this.container.clientHeight;

    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };

  init = () => {
    requestAnimationFrame(this.animate);
  };

  animate = () => {
    if (!document.body.contains(this.container)) {
      return;
    }

    requestAnimationFrame(this.animate);

    if (!this.manualControl && this.planetaryParticles) {
      this.planetaryParticles.rotation.y += this.orbitSpeed;
      this.planetaryParticles.rotation.z += this.orbitSpeed;
    } else if (this.manualControl && this.planetaryParticles) {
      this.planetaryParticles.rotation.y += (this.mouse.x * 0.5 - this.planetaryParticles.rotation.y) * this.lerpSpeed;
      this.planetaryParticles.rotation.x += (this.mouse.y * 0.5 - this.planetaryParticles.rotation.x) * this.lerpSpeed;
    }

    this.renderer.render(this.scene, this.camera);
  };
}

export default PlanetaryParticles;
